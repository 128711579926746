var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Event } from '@syncfusion/ej2-base';
import { NotifyPropertyChanges, Property, addClass } from '@syncfusion/ej2-base';
import { SignatureBase } from '../common/signature-base';
/**
 * The Signature component allows user to draw smooth signatures as vector outline of strokes using variable width bezier curve interpolation.
 * It allows to save signature as image.
 * You can use your finger, pen, or mouse on a tablet, touchscreen, etc., to draw your own signature on this Signature component.
 * Signature component is a user interface to draw the Signature or Text.
 * It provides supports for various Background color, Stroke color and Background Image.
 * ```html
 * <canvas id="signature"></canvas>
 * ```
 * ```typescript
 * <script>
 *   let signatureObj: Signature = new Signature(null , "#signature");
 * </script>
 * ```
 */
let Signature = class Signature extends SignatureBase {
    /**
     * Constructor for creating the widget.
     *
     * @param {SignatureModel} options - Specifies the Signature model.
     * @param {string | HTMLCanvasElement} element - Specifies the element.
     * @private
     */
    constructor(options, element) {
        super(options, element);
    }
    preRender() {
        // pre render code snippets
    }
    /**
     * To Initialize the component rendering
     *
     * @private
     * @returns {void}
     */
    render() {
        this.initialize();
    }
    initialize() {
        addClass([this.element], 'e-' + this.getModuleName());
        super.initialize(this.element);
        if (this.enablePersistence) {
            this.loadPersistedSignature();
        }
    }
    /**
     * To get component name.
     *
     * @returns {string} - Module Name
     * @private
     */
    getModuleName() {
        return 'signature';
    }
    /**
     * To get the properties to be maintained in the persisted state.
     *
     * @returns {string} - Persist data
     */
    getPersistData() {
        return this.addOnPersist(['signatureValue']);
    }
    /**
     * Called internally if any of the property value changed.
     *
     * @param  {SignatureModel} newProp - Specifies new properties
     * @param  {SignatureModel} oldProp - Specifies old properties
     * @returns {void}
     * @private
     */
    onPropertyChanged(newProp, oldProp) {
        for (const prop of Object.keys(newProp)) {
            switch (prop) {
                case 'backgroundColor':
                    super.propertyChanged(prop, newProp.backgroundColor);
                    break;
                case 'backgroundImage':
                    super.propertyChanged(prop, newProp.backgroundImage);
                    break;
                case 'strokeColor':
                    if (newProp.strokeColor !== oldProp.strokeColor) {
                        super.propertyChanged(prop, newProp.strokeColor);
                    }
                    break;
                case 'saveWithBackground':
                    super.propertyChanged(prop, newProp.saveWithBackground);
                    break;
                case 'isReadOnly':
                    super.propertyChanged(prop, newProp.isReadOnly);
                    break;
                case 'disabled':
                    super.propertyChanged(prop, newProp.disabled);
                    break;
            }
        }
    }
};
__decorate([
    Property('')
], Signature.prototype, "backgroundColor", void 0);
__decorate([
    Property('')
], Signature.prototype, "backgroundImage", void 0);
__decorate([
    Property(false)
], Signature.prototype, "disabled", void 0);
__decorate([
    Property(false)
], Signature.prototype, "isReadOnly", void 0);
__decorate([
    Property(true)
], Signature.prototype, "saveWithBackground", void 0);
__decorate([
    Property('#000000')
], Signature.prototype, "strokeColor", void 0);
__decorate([
    Property(0.5)
], Signature.prototype, "minStrokeWidth", void 0);
__decorate([
    Property(2)
], Signature.prototype, "maxStrokeWidth", void 0);
__decorate([
    Property(0.7)
], Signature.prototype, "velocity", void 0);
__decorate([
    Property('en-US')
], Signature.prototype, "locale", void 0);
__decorate([
    Property(false)
], Signature.prototype, "enableRtl", void 0);
__decorate([
    Property(false)
], Signature.prototype, "enablePersistence", void 0);
__decorate([
    Event()
], Signature.prototype, "beforeSave", void 0);
__decorate([
    Event()
], Signature.prototype, "change", void 0);
__decorate([
    Event()
], Signature.prototype, "created", void 0);
Signature = __decorate([
    NotifyPropertyChanges
], Signature);
export { Signature };
